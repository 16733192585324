import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import { isProduction } from '../../helper';
import GTMEventList from './GTMEventList';
import template from 'lodash/template';

const getGTMId = () => {
  return isProduction() ? process.env.REACT_APP_GTM_ID : process.env.REACT_APP_GTM_ID_DEV;
}

export const initializeGTM = () => {
  let gtmId = getGTMId();
  if (gtmId) {
    const tagManagerArgs = {
      gtmId: gtmId,
      dataLayername: process.env.REACT_APP_GTM_DATALAYER_NAME
    }
    //TagManager.initialize(tagManagerArgs);
  }
}

export const pushToDataLayer = (eventName, replacements, dataLayername, dataObject) => {
  let data = null;
  if (GTMEventList.hasOwnProperty(eventName)) {
    data = Object.assign({}, GTMEventList[eventName], {});

    if (replacements) {
      data = JSON.stringify(data);
      var t = template(data);
      data = JSON.parse(t(replacements));
    }
  }
  else {
    data = Object.assign({}, dataObject, {});
  }

  if (data) {
    const tagManagerArgs = {
      dataLayername: process.env.REACT_APP_GTM_DATALAYER_NAME,
      dataLayer: data
    }

    //TagManager.dataLayer(tagManagerArgs);
  }
}

export const pushCustomGAEvent = (data = {}) => {
  const eventData = data;
  if (data.eventCategory) eventData.category = data.eventCategory;
  if (data.eventAction) eventData.action = data.eventAction;
  if (data.eventLabel) eventData.label = data.eventLabel;
  window && window.gtag("event", "customEvent", eventData);
  ReactGA.event(eventData);
};

