import React, { Component } from "react";
import "../../asset/Style.css";
import "./index.css";
import thumb1 from "../../asset/img/thumb1.png";
import thumb2 from "../../asset/img/thumb2.png";
import emoji from "../../asset/img/emoji.png";
import dislikeThumb from "../../asset/img/dislike.png";
import likeThumb from "../../asset/img/like.png";
import Help from "../Help";
import Header from "./Header";
import loader from "../../asset/img/loader.gif";
import Project from "../../Configure/Project";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import { pushCustomGAEvent, pushToDataLayer } from "../../shared/FaGTM";
import FaSession from "../../helper/FaSession";
import { isFloweraura } from "../../helper";
import DislikeParameter from "./DislikeParameter";

let orderComment = [];
let mapping_id = 0;

class CustomerFeedBack extends Component {
    constructor(props) {
        super(props);
        mapping_id = this.props.match.params.id;
        this.state = {
            toggle: false,
            showNegativeValue: false,
            selectedOption: "",
            error: null,
            items: [],
            selectedButtonStatus: null,
            selectedButton: [],
            selectedBtndId: false,
            open: true,
            order: 2,
            buttonState: "Btn2",
            thumbshowDislike: thumb2,
            thumbshowLike: thumb1,
            ContinueButtonRes: false,
            isActiveDislike: "circle",
            isActiveLike: "circle first",
            customer_name: "",
            agent_name: "",
            headerMsg: "Thank you for choosing Bakingo. We hope our services met your expectations!",
            userMobile: "",
            negativeResponse: null,
            showToken: false,
            showOnlyFirstTime: true
        };

        this.commentRef = React.createRef();
    }

    getCommentRef = (element) => {
        this.commentRef = element;
    };

    scrollToMyRef = (type) => {
        if (type === "comment" && this.commentRef !== null) {
            return window.scrollTo({
                top: this.commentRef.offsetTop,
                behavior: "smooth",
            });
        }

        return 0;
    };

    componentDidMount() {
        if(isFloweraura()) this.setState({headerMsg: "Thank you for choosing Floweraura. We hope our services met your expectations!"});
        else this.setState({headerMsg: "Thank you for choosing Bakingo. We hope our services met your expectations!"});
        
        axios
            .get(Project.agentBaseUrl + "Customer_feedback_api/tokenGenerate", {
                headers: {
                    'Headeroprkey': `FrdfHSFsdetrESRSDDCSDg9ytrewtwerR`
                }
            })
            .then((res) => {
                localStorage.setItem("token", res.data.token)
                this.setState({
                    showToken: true
                })
            })
            .catch((err) => {
                // Handle the error here.
                console.log(err);
            });
    }

    componentDidUpdate() {
        const headers = {
            'Auth': localStorage.getItem("token") || ''
        }
        if(this.state.showToken && this.state.showOnlyFirstTime) {
            this.setState({
                showOnlyFirstTime: false,
            })
            axios({
                method: "post",
                headers: headers,
                url: Project.agentBaseUrl + "Customer_feedback_api/getAgentCustomerName",
                data: {
                    "mapping_id": mapping_id,
                }
            })
                .then(response => {
                    if (response && response.status === 200) {
                        this.setState({
                            isLoaded: true,
                            ContinueButtonRes: false,
                            customer_name: response.data.customer_name,
                            agent_name: response.data.agent_name
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
    }

    dislikeImg = (e) => {
        const elementId = e.target.getAttribute("id");

        if (elementId === "like") {
            // var comment_type = 1;
            this.setState({ selecteBtndId: 0, selectedButtonStatus: 1 });
            const eventData = {
                'action': "Step 1",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': 'Like'
              }
            pushCustomGAEvent(eventData)
            pushToDataLayer("clickEvent", eventData);
            //pushToDataLayer("DeliveryStatusClick", { status: "Like" });
        } else {
            const eventData = {
                'action': "Step 1",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': 'Dislike'
              }
            pushCustomGAEvent(eventData)
            pushToDataLayer("clickEvent", eventData);
            //pushToDataLayer("DeliveryStatusClick", { status: "Dislike" });
            this.setState({ selectedButtonStatus: 2 });
            // this.props.history.push("/Negative")
            // comment_type = 2;
        }
        setTimeout(() => {
            this.scrollToMyRef("comment");
        }, 100);

        this.setState({
            thumbshowDislike: dislikeThumb,
            thumbshowLike: thumb1,
            isActiveDislike: "circle active",
            isActiveLike: "circle first",
        });
    };

    likeImg = (e) => {
        const elementId = e.target.getAttribute("id");

        if (elementId === "like") {
            // var comment_type = 1;
            this.setState({ selecteBtndId: 0, selectedButtonStatus: 1 });
            const eventData = {
                'action': "Step 1",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': 'Like'
              }
            pushCustomGAEvent(eventData)
            pushToDataLayer("clickEvent", eventData);
            //pushToDataLayer("DeliveryStatusClick", { status: "Like" });
        } else {
            const eventData = {
                'action': "Step 1",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': 'Dislike'
              }
            pushCustomGAEvent(eventData)
            pushToDataLayer("clickEvent", eventData);
            //pushToDataLayer("DeliveryStatusClick", { status: "Dislike" });
            this.setState({ selectedButtonStatus: 2 });
            // comment_type = 2;
        }
        setTimeout(() => {
            this.scrollToMyRef("comment");
        }, 100);

        this.setState({
            thumbshowDislike: thumb2,
            thumbshowLike: likeThumb,
            isActiveDislike: "circle",
            isActiveLike: "circle first active",
        });
    };

    toaster = (msg) => toast(msg, { containerId: "A", position: "Top", duration: "500" });

    toggleImage = () => {
        this.setState((state) => ({ open: !state.open }));
    };

    _handleOnClick = (e) => {
        this.setState({ ContinueButtonRes: true });
        const elementId = e.target.getAttribute("id");

        if (elementId === "like") {
            var comment_type = 1;
            this.setState({ selecteBtndId: 0, selectedButtonStatus: 1 });
            const eventData = {
                'action': "Step 1",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': 'Like'
              }
            pushCustomGAEvent(eventData)
            pushToDataLayer("clickEvent", eventData);
            //pushToDataLayer("DeliveryStatusClick", { status: "Like" });
        } else {
            const eventData = {
                'action': "Step 1",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': 'Dislike'
              }
            pushCustomGAEvent(eventData)
            pushToDataLayer("clickEvent", eventData);
            //pushToDataLayer("DeliveryStatusClick", { status: "Dislike" });
            this.setState({ selectedButtonStatus: 2 });
            comment_type = 2;
        }
        setTimeout(() => {
            this.scrollToMyRef("comment");
        }, 100);
    };

    tip = () => {
        const eventData = {
            'action': "Tips",
            'eventCategory': 'Updated_Feedback',
            'eventLabel': "Step1"
          }
        pushCustomGAEvent(eventData)
        pushToDataLayer("clickEvent", eventData);
        //pushToDataLayer("TipsClick", { page: "Step1" });
        this.setState({ toggle: !this.state.toggle });
    };

    _isButtonActive(buttonId) {
        return this.state.activeButton === buttonId;
    }

    _handleSubmit = (value) => {
        if (orderComment.length <= 0 || this.state.selectedButtonStatus === 1) {
            this.setState.selectedBtndId = "0";
        } else {
            this.setState.selectedBtndId = orderComment.toString();
        }
        if (this.state.selectedButtonStatus) {
            FaSession.set("isOrderFeedbackSubmitted", true);

            let submitData = "Submit | ";
            if (this.setState.selectedBtndId !== "0") {
                submitData += "Dislike";
            } else {
                submitData += "Like";
            }

            //let thumbType = 'Like';
            if (this.setState.selectedBtndId !== "0") {
                let selectedComments = this.setState.selectedBtndId.split(",");
                let commentList = this.state.items;

                const getComment = selectedComments.map((commentId) => {
                    const machedComment = commentList.filter(
                        (row) => row.id === parseInt(commentId)
                    );
                    return machedComment[0].name;
                });

                if (getComment.length > 0) {
                    submitData += " | " + getComment.join(" , ");
                }
            }

            const eventData = {
                'action': "Step 1",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': submitData
              }
              pushCustomGAEvent(eventData)
              pushToDataLayer("clickEvent", eventData);
            //pushToDataLayer("DeliverySubmit", { submitData });
            this.setState({ ContinueButtonRes: true });

            const headers = {
                // 'Origin':'bakewish',
                'Auth': localStorage.getItem("token") || ''
            }
            axios({
                method: "post",
                headers: headers,
                url: Project.agentBaseUrl + "Customer_feedback_api/saveCustomerFeedback/" + value,
                data: {
                    "mapping_id": mapping_id,
                    "feedback": value
                }
            })
                .then(response => {
                    if (response && response.status === 200) {
                        this.setState({
                            isLoaded: true,
                            ContinueButtonRes: false,
                        });
                        localStorage.setItem("insert_id", response.data.insert_id)
                        if (response.data.alreadyGiven === 1)
                            return this.toaster("Feedback has been already given for this mapping id!");
                        else if (value === 1) {
                            this.props.history.push("/Feedback")
                        }
                        else {
                            this.setState({
                                negativeResponse: response.data.show_not_satisfied_parameter_to_user,
                                showNegativeValue: true,
                            })
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        } else {
            this.toaster("Please select feedback");
        }
    };

    render() {
        let hiddenclassName = this.state.showNegativeValue ? "hidden_class" : ""
        return (
            <div className="container">
                <ToastContainer
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP}
                    duration={5000}
                />
                <Header
                    help={() => this.tip()}
                    customer_name={this.state.customer_name}
                    content={this.state.headerMsg}
                    hiddenclassName={hiddenclassName}
                />

                <div className={`progress-bar ${hiddenclassName}`} />
                <div className={`detail-box ${hiddenclassName}`}>
                    <p className="font">
                        You were interacting with me, {this.state.agent_name}, and I would love to know your feedback of our interaction.
                    </p>
                    <div className={isFloweraura() ? "review-box fa" : "review-box bk"}>
                        <div
                            className={this.state.isActiveLike}
                            // onClick={this._handleOnClick}
                            id="like"
                        >
                            <img
                                className="thumb"
                                src={this.state.thumbshowLike}
                                id="like"
                                alt="thumb1"
                                onClick={this.likeImg}
                            />
                        </div>
                        <div
                            //className="circle"
                            // onClick={this._handleOnClick}
                            id="dislike"
                            className={this.state.isActiveDislike}
                        >
                            {this.state.ContinueButtonRes && (
                                <div>
                                    <div className="overlay-loader" />
                                    <img className="loader" src={loader} alt="loader" />
                                </div>
                            )}
                            <img
                                className="thumb"
                                src={this.state.thumbshowDislike}
                                id="dislike"
                                alt="thumb2"
                                onClick={this.dislikeImg}
                            />
                        </div>
                    </div>
                    {/* {this.state.selectedButtonStatus === 2
                        && (
                            <div className="flow" ref={this.getCommentRef}>
                                <div className="line" />
                                {items.data.length > 0 && (
                                    <p className="font-btn font-title">Let me know where I failed to give the best, so that I can work on it next time:</p>
                                )}
                                {this.state.items &&
                                    this.state.items.map((item, index) => (
                                        <button
                                            key={index}
                                            className={item.is_active ? "Btn1" : "Btn2"}
                                            onClick={() => this.buttonClick(item,index)}
                                        >
                                            {item.name}
                                        </button>
                                    ))}
                            </div>
                        )} */}
                    {this.state.selectedButtonStatus === 1 ? <div className="like-message_show" ref={this.getCommentRef}>
                        <div className="line" />
                        <img src={emoji} />
                        <p>I am happy that I made you happy.</p>
                    </div> : null
                    }
                </div>
                {this.state.toggle ? (
                    <div
                        className="overlay-box"
                        onClick={() => this.setState({ toggle: false })}
                    />
                ) : null}
                {this.state.toggle ? <Help /> : null}
                {this.state.ContinueButtonRes && (
                    <div>
                        <div className="overlay-loader" />
                        <img className="loader" src={loader} alt="loader" />
                    </div>
                )}
                <div className={`btn-background ${hiddenclassName}`}>
                    <div className="progress-bar1" />
                    <button
                        // disabled={this.state.selectedOption.length > 0 ? false : true}
                        className={
                            //   this.state.selectedOption.length > 0
                            isFloweraura() ? "submit-btn1 fa btnactive" : "submit-btn1 bk btnactive"
                            // "submit-btn1"
                        }
                        onClick={this._handleSubmit.bind(this, this.state.selectedButtonStatus)}
                        id="submit"
                    >
                        Continue
                    </button>

                </div>
                {this.state.selectedButtonStatus === 2 && this.state.showNegativeValue && this.state.negativeResponse !== null &&
                    <DislikeParameter 
                        negativeResponse={this.state.negativeResponse}
                        toaster={this.toaster}
                    />}
            </div>
        );
    }
}

export default CustomerFeedBack;
