import React, { Component } from 'react';
import Cookies from "js-cookie";
import { pushCustomGAEvent, pushToDataLayer } from "../shared/FaGTM";
import Header from "../Component/Header";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Project from "../Configure/Project";
import Help from "../Component/Help";
import loader from "../asset/img/loader.gif";
import { isFloweraura } from '../helper';

let order_id = 0;

export default class Email extends Component {
    constructor(props){
        super(props);
        order_id = this.props.match.params.id;
        this.state = {
            email: "",
            toggle: false,
            name: Cookies.get(`${order_id}`),
            mobile: Cookies.get('userMobile'),
            headerMsg: "Glad our gift could make you jump in joy!",
            ContinueButtonRes: false,
            message : false,
        }
    }

    tip = () => {
      const eventData = {
        'action': "Tips",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': "Step1"
      }
      pushCustomGAEvent(eventData)
      pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer('TipsClick',{page:'Step1'})
        this.setState({ toggle: !this.state.toggle });
    };

    _handleEmail = (e) => {
      this.setState({
          email : e.target.value
      })
    }

    toaster = msg => {
      toast(msg, { containerId: "B", position: "Top", duration: "500" })
    }

    handleSubmit = () => {
      this.setState({ ContinueButtonRes: true });
      axios({
        method: "post",
        url: Project.apiBaseUrl + "order/update-feedback/",
        data: {
          order_id : order_id,
          email : this.state.email
        }
      })
      .then(response => {
        if(response.data.status!==200){
          this.toaster(response.data.msg);
        } else {
          const eventData = {
            'action': this.state.email,
            'eventCategory': Cookies.get('userMobile'),
            'eventLabel': Cookies.get(`${order_id}`)
          }
          pushCustomGAEvent(eventData)
          pushToDataLayer("userDataPush", eventData);
          // pushToDataLayer('userDataPush',{},{},{
          //   event : 'userDataPush',
          //   userEmail: this.state.email,
          //   userMobile: Cookies.get('userMobile'),
          //   userName: Cookies.get(`${order_id}`)
          // })
          this.setState({
            email: '',
            ContinueButtonRes: false
          })
          this.props.history.push('/Thankyou/' + order_id,
            {
              feedback_submit: true
            }
          )
          localStorage.setItem("orderIdFeedbackStatus", true);
        }
      })
  
      .catch(error => {
        this.toaster("Data not found!");
      });
    }

    onKeyboardOpen = () => {
      // setTimeout(function(){
      //   const element = document.getElementById('email-box')
      //   element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      // },1500);
    }

    componentDidMount(){
      // setTimeout(function(){
      //   const element = document.getElementById('email-box')
      //   element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      // },1500)
    }

    render() {
      const emailTester = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      // let value = false
        return (
          <div className="container container-revamp">
            <ToastContainer
              enableMultiContainer
              containerId={"B"}
              position={toast.POSITION.TOP}
              duration={5000}
            />
            <Header
              help={() => this.tip()}
              name={Cookies.get(`${order_id}`)}
              content={this.state.headerMsg}
            />
            {this.state.toggle ? (
              <div
                className="overlay-box"
                onClick={() => this.setState({ toggle: false })}
              />
            ) : null}
            {this.state.toggle ? <Help /> : null}
            {this.state.ContinueButtonRes && (
              <div>
                <div className="overlay-loader" />
                <img className="loader" src={loader} alt="loader" />
              </div>
            )}
            <div className="detail-contentInner">
            <div className="detail-box">
              {/* <div className="progress-bar" /> */}
              <em></em>
              <h2>Hi {Cookies.get(`${order_id}`)},</h2>
              <p className="font emailFont">
                Enter your email address and enjoy a coupon of <span>15% Discount!</span>
              </p>
    
              <div className="review-box review-like-email">
              <span className="emailIcon"></span>
                <input
                  onFocus={this.onKeyboardOpen}
                  id = "email-box"
                  type= 'email'
                  name= 'email'
                  placeholder= 'Email Id'
                  className= 'email-input'
                  onChange={this._handleEmail}
                />
                {emailTester.test(this.state.email) && <span className="signTick"></span>}
              </div>
              {this.state.message && <p className="validErroMessage">You have entered wrong email ID</p>}
            </div>
            <div className={isFloweraura() ? "btn-background btn-revamp fa" : "btn-background btn-revamp bk"}>
              {/* <div className="progress-bar1" /> */}
              <button
                disabled={emailTester.test(this.state.email) ? false : true}
                className={
                  emailTester.test(this.state.email)
                    ? "submit-btn1 btnactive"
                    : "submit-btn1"
                }
                onClick={() => this.handleSubmit()}
                id="submit1"
              >
                Submit
              </button>
              </div>
            </div>
          </div>
        );
    }
}