import React, { Component } from "react";
import "../../asset/Style.css";
import axios from "axios";
import Project from "../../Configure/Project";
import loader from "../../asset/img/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import Header from "./Header";
import { isFloweraura } from "../../helper";

const items = {
    "data": [
        {
            "id": 27,
            "name": "Customer Service experience",
            "is_active": false
        },
        {
            "id": 28,
            "name": "Delivery Experience",
            "is_active": false
        },
        {
            "id": 29,
            "name": "Quality of Product",
            "is_active": false
        },
        {
            "id": 30,
            "name": "Website & App experience",
            "is_active": false
        }
    ],
    "msg": "Success"
}

let orderComment = [];

class DislikeParameter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: "",
            items: items.data,
            selectedButton: [],
            selectedBtndId: false,
            ContinueButtonRes: false,
            selectButtonParent: false,
            setParentTitle: "",
            selectButtonSubMenu: false,
            setSubMenuTitle: "",
            negativeData: this.props.negativeResponse,
            subMenuValue: false,
        };
    }

    toaster = (msg) =>
        toast(msg, { containerId: "A", position: "Top", duration: "500" });

        buttonClick = (item) => {
            if (typeof orderComment == "string") {
                orderComment = orderComment.split(" ");
            }
            if (orderComment === 0) {
                orderComment = [];
            }
    
            let commentFound = orderComment.indexOf(item.name);
            if (commentFound !== -1) {
                orderComment.splice(commentFound, 1);
            } else {
                orderComment.push(item.name);
            }
            this.setState((prevState) => {
                return {
                    items: prevState.items.map((data) => {
                        if (data.id === item.id) {
                            return { ...data, is_active: !item.is_active };
                        } else {
                            return data;
                        }
                    }),
                    selectedButton: item.name,
                    selectedBtndId: this.state.selectedBtndId + item.id + ",",
                };
            }); 
        };

        parentButtonClick = (item) => {
            this.setState({
                selectButtonParent: true,
                setParentTitle: item
            })
        }
    
        subMenuButtonClick = (title) => {
            this.setState({
                selectButtonSubMenu: true,
                setSubMenuTitle: title
            })
        }

        showSubMenu = (orderComment, value) => {
            if (value && orderComment.toString() !== "") {
                this.setState ({
                    subMenuValue: true
                })
            } else {
                this.props.toaster("Please click feedback here");
            }
        }

        handleSubmit = (orderComment,value) => {
            this.setState({
                ContinueButtonRes: true,
            })
            const headers = {
                // 'Origin':'bakewish',
                'Auth': localStorage.getItem("token") || ''
            }
            axios({
                method: "post",
                headers:headers,
                url: Project.agentBaseUrl + "/Customer_feedback_api/saveCustomerNotSatisfiedParameter",
                data: {
                    "insert_id":localStorage.getItem("insert_id"),
                    "not_satisfied_parameter": orderComment.toString(),
                    "not_satisfied_sub_parameter": this.state.setSubMenuTitle.toString()
                }
            })
                .then(response => {
                    if (response && response.status === 200) {
                        this.setState({
                            ContinueButtonRes: false,
                            // selectedButton:[].toString(),
                        })
                        if(response.data.alreadyGiven === 1) 
                        return this.props.toaster("Parameter already has been given for this maaping id!");
                        else {
                            window.location = `${Project.BASE_URL}/Feedback`;
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
    render() {
        let subMenus = null;
        let parentMenu = null;
        const {negativeData, selectButtonParent, setParentTitle, selectButtonSubMenu, setSubMenuTitle, subMenuValue} = this.state;
        if ((negativeData !== null && negativeData !== undefined)) { 
            parentMenu = Object.keys(negativeData).map((menuId) => {
            return (
                <button
                key={menuId}
                className= {selectButtonParent && setParentTitle === negativeData[menuId].title ? "Btn1" : "Btn2"}
                onClick={() => this.parentButtonClick(negativeData[menuId].title)}
            >
                {negativeData[menuId].title}
            </button>
            )
          });
        }
        if (setParentTitle !== "") {  
            subMenus = negativeData[setParentTitle].submenus.map((item, key)=> {
                return (
                    <button
                    key={key}
                    className ={selectButtonSubMenu && setSubMenuTitle === item.title ? "Btn1" : "Btn2"}
                    onClick={() => this.subMenuButtonClick (item.title)}
                >
                    {item.title}
                </button>
                )
            })
        }

        return (
            <>
                {this.state.ContinueButtonRes && (
                    <div>
                        <div className="overlay-loader" />
                        <img className="loader" src={loader} alt="loader" />
                    </div>
                )}
                <div className="feedback-show">
                    <h2 className="dislike-heading">It’s really disheartening to learn that I missed to match up to your expectations.</h2>
                    <div className="progress-bar" />
                    <div className="detail-box">
                        <div className={isFloweraura() ? "flow negativetagsFA" : "flow negativetagsBK"}>
                            {Object.keys(negativeData).length > 0 && (
                                <p className="font-btn font-title parameter_content">Let me know where I failed to give the best, so that I can work on it next time:</p>
                            )}
                            {subMenuValue ? subMenus : parentMenu}
                        </div>
                    </div>
                    
                    {!subMenuValue ?
                    <div className="btn-background hello">
                    <div className="progress-bar1"></div>
                        <button
                            className={isFloweraura() ? "submit-btn1 btnactive fa continue-shopping" : "submit-btn1 btnactive bk continue-shopping"}
                            onClick={() => this.showSubMenu(setParentTitle,selectButtonParent)}
                        >
                            Continue
                    </button>

                    </div> :
                    <div className={isFloweraura() ? "skip-submit fa": "skip-submit bk"}>
                    <div className="progress-bar1"></div>
                        <div className="two-button_set">
                            <button className="skip-btn" onClick={this.handleSubmit.bind(this,setParentTitle,selectButtonParent)}>
                                SKIP
                    </button>
                            <button id="submit" className="done-btn" onClick={this.handleSubmit.bind(this,setParentTitle,selectButtonParent)}>
                            Continue
                    </button>
                        </div>
                    </div>}
                </div>
            </>
        );
    }
}
export default DislikeParameter;
