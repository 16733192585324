import React, { Component } from 'react';
import Cookies from "js-cookie";
import { pushCustomGAEvent, pushToDataLayer } from "../shared/FaGTM";
import Header from "../Component/Header";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Project from "../Configure/Project";
import Help from "../Component/Help";
import loader from "../asset/img/loader.gif";
import { isFloweraura } from '../helper';

let order_id = 0;

export default class FeedbackDetail extends Component {
    constructor(props){
        super(props);
        order_id = this.props.match.params.id;
        this.state = {
            detail: "",
            toggle: false,
            name: Cookies.get(`${order_id}`),
            headerMsg: "Apologies as we couldn't meet your expectations.",
            disableSubmit : false,
            textareaOpen: false
        }
    }

    tip = () => {
      const eventData = {
        'action': "Tips",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': "Step1"
      }
      pushCustomGAEvent(eventData)
      pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer('TipsClick',{page:'Step1'})
        this.setState({ toggle: !this.state.toggle });
    };

    _handleDetail = (e) => {
        this.setState({
            detail : e.target.value,
            disableSubmit: false
        })
    }

    toaster = msg => {
      this.setState({
        disableSubmit : true
      })
      toast(msg, { containerId: "B", position: "Top", duration: "500" })
    }

    handleSubmit = () => {
        axios({
            method: "post",
            url: Project.apiBaseUrl + "order/update-feedback/",
            data: {
              order_id : order_id,
              feedback : this.state.detail
            }
          })
            .then(response => {
              if(response.data.status!==200){
                this.toaster(response.data.msg);
              } else {
                this.setState({
                  detail: ''
                })
                // this.props.history.push('/Thankyou/' + order_id,
                //   {
                //     feedback_submit : true
                //   }
                // )
                this.props.history.push("/Email/" + order_id);
                localStorage.setItem("orderIdFeedbackStatus", true);
              }
            })
    
            .catch(error => {
              this.toaster("Data not found!");
            });
    }

    onKeyboardOpen = () => {
      // setTimeout(function(){
      //   const element = document.getElementById('feedback-box')
      //   element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      // },1500)
      this.setState({
        textareaOpen: true
      })
    }

    componentDidMount(){
      // setTimeout(function(){
      //   const element = document.getElementById('feedback-box')
      //   element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      // },1500)
    }

    render() {
        return (
          <div className="container container-revamp">
            <ToastContainer
              enableMultiContainer
              containerId={"B"}
              position={toast.POSITION.TOP}
              duration={5000}
            />
            <Header
              help={() => this.tip()}
              name={Cookies.get(`${order_id}`)}
              content={this.state.headerMsg}
            />
            {this.state.toggle ? (
              <div
                className="overlay-box"
                onClick={() => this.setState({ toggle: false })}
              />
            ) : null}
            {this.state.toggle ? <Help /> : null}
            {this.state.ContinueButtonRes && (
              <div>
                <div className="overlay-loader" />
                <img className="loader" src={loader} alt="loader" />
              </div>
            )}
            <div className="detail-contentInner">
            <div className="detail-box">
              {/* <div className="progress-bar" /> */}
              <em></em>
              <h2>Hi {Cookies.get(`${order_id}`)},</h2>
              <p className="font font-feedback">
                We would appreciate a detailed feedback to serve you better in future
              </p>
    
              <div className="review-box review-like-revamp">
                <textarea
                  onFocus={this.onKeyboardOpen}
                  id="feedback-box" 
                  placeholder="Write here (optional)"
                  onChange={this._handleDetail}
                  className="detail-feedback"
                  // onBlur={()=> this.setState({
                  //   textareaOpen: false
                  // })}
                />
              </div>
            </div>
            <div className={isFloweraura() ? "btn-background btn-revamp fa" : "btn-background btn-revamp bk"}>
              {/* <div className="progress-bar" /> */}
              <button
                //disabled={(this.state.detail.length === 0 || this.state.disableSubmit) ? true : false}
                className={this.state.textareaOpen ? "submit-btn1  btnactive adjustButton" : "submit-btn1  btnactive"
                  // (this.state.detail.length === 0 || this.state.disableSubmit)
                  //   ? "submit-btn1"
                  //   : "submit-btn1  btnactive"
                }
                onClick={() => this.handleSubmit()}
                id="submit1"
              >
                Submit
              </button>
              </div>
            </div>
          </div>
        );
    }
}