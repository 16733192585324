import React, { Component } from "react";
import "../../asset/Style.css";
import thankyou from "../../asset/img/thankyou.png";
import { isFloweraura } from "../../helper";
import Header from "./Header";
class ThankyouPage extends Component {
    render() {
        return (
            <div className="container">
                <div className="feedback-show">
                    <Header />
                    <div className="thankyou-image_content">
                        <div className="thankyou-image">
                            <img src={thankyou} />
                        </div>
                        <div className="thankyou-content">
                            Thank you for feedback, its really important to us. We will surely continue to make your celebrations Happy and Joyful in future.
                    </div>
                    </div>
                    <div className="btn-background btn-botom">
                        <div className="progress-bar1" />
                        <a
                            href={
                                isFloweraura()
                                    ? "https://www.floweraura.com/"
                                    : "https://www.bakingo.com/"
                            }
                            className={isFloweraura() ? "submit-btn1 btnactive fa continue-shopping" : "submit-btn1 btnactive bk continue-shopping"}
                        >
                            Continue Shopping
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default ThankyouPage;
