import React, { Component } from "react";
import "../asset/Style.css";
// import path from "../asset/img/path.png";
import thankouSign from "../asset/img/thankSign.png";
import Product from "../Component/Product";
import _ from "lodash";
import { pushCustomGAEvent, pushToDataLayer } from "../shared/FaGTM";
import { isFeedbackSubmitted, isFloweraura } from "../helper";
import FaSession from "../helper/FaSession";
import Header from "./Header";
//import FetchApi from "./FetchApi";

let order_id = 0;

class Thankyou extends Component {
  constructor(props) {
    super(props);
    _.isEmpty(this.props)
      ? (order_id = 0)
      : (order_id = this.props.match.params.id);
  }

  componentDidMount() {
    const state = this.props.location.state;
    if (state === undefined || state.feedback_submit !== true) {
      this.props.history.push("/home/" + order_id);
    } else if (
      state !== undefined &&
      state.feedback_submit === true &&
      isFeedbackSubmitted("isFeedbackAlreadySubmitted") === false
    ) {
      const eventData = {
        'action': "Step 3",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': "Thank You"
      }
      pushCustomGAEvent(eventData)
      pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer("ThankyouPageLoad");
      FaSession.set("isFeedbackAlreadySubmitted", true);
    } else if (isFeedbackSubmitted("isFeedbackAlreadySubmitted")) {
      const eventData = {
        'action': "Step 1",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': "Already submitted"
      }
      pushCustomGAEvent(eventData)
      pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer("AlreadySubmittedPageLoad");
    }
  }

  render() {
    let status = localStorage.getItem("orderIdFeedbackStatus");
    return (
      <div className="container container-revamp">
        {/* <div className="overlay-box" /> */}
        <Header
          help=""
          name=""
          content=""
          notshowTip={true}
          />
        {/* <Product order_id_Thanks={order_id} feedback_submit={true} /> */}
        <div className="More-Feedback detail-contentInner revamp-feedback">
        <div className="lottify-check">
          <div className="detail-box">
          <div className="rectangle">
            <img className="path" src={thankouSign} alt="thankouSign" />
          </div>
          {/* <h2 className="More-heading">Thank You</h2> */}
          {status === "true" ? (
            // <p className="Thanks-text">
            //   Your feedback has been submitted already.
            //   <br />
            //   Send gift to your loved ones!
            // </p>
            <p className="thankyouFont">
              Yay! Your Discount Coupon is sent to your <span>Email ID</span>
            </p>
          ) : (
            // <p className="Thanks-text">
            //   Thanks for sharing your feedback.
            //   <br />
            //   We are trying to give you the best gifting experience.
            //   <br />
            //   Keep shopping with us!
            // </p>
            <p className="thankyouFont">
              Yay! Your Discount Coupon is sent to your <span>Email ID</span>
            </p>
          )}

        </div>
          <div className="feedback btn-revamp">
            <a
              href={
                isFloweraura()
                  ? "https://www.floweraura.com/"
                  : "https://www.bakingo.com/"
              }
              className="link-col"
            >
              Shop More
            </a>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Thankyou;
