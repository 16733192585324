import axios from 'axios';
import Project from "../Configure/Project";

export default class FetchApi {

  static getData = (url) => {
    return new Promise((resolve, reject) => {
      return axios.get(Project.apiBaseUrl + url).then((response) => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    })
  }
}
