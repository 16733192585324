import React, { Component } from "react";
import "../asset/Style.css";
import logo from "../asset/img/logo.png";
import newLogo from "../asset/img/faFinal_logo.png";
import bulb from "../asset/img/bulb.png";
import newBulb from "../asset/img/icons-tip.png";
import backgrd from "../asset/img/backgrd.png";
import bkbackgrd from "../asset/img/bk-backgrd.png";
import { isFloweraura } from "../helper";
let userName = "";
let contents = "";
//let msgStatus = "";
const bklogo = "https://media.bakingo.com/sites/default/files/ssr-static/bakingo.svg";
const falogo = "https://imgcdn.floweraura.com/fa-logo/final-23-desk.svg";
class Header extends Component {
  render() {
    userName = this.props.name;
    contents = this.props.content;
    return (
      <div>
        <img className="Group-img" src={isFloweraura() ? backgrd : bkbackgrd} alt="backgrd" />
        <div className="header new_header">
          <img
            className="logo"
            src={isFloweraura() ? falogo : bklogo}
            alt="logo"
          />
      {this.props.notshowTip ? null :
          <div onClick={() => this.props.help()} className="tip-bar">
            <img
              className="bulb"
              src={newBulb}
              alt="bulb"
            />
            <p>Quick Tip</p>
          </div>}
          <div className="heading">
            {/* <p> Hey {userName},</p> */}
            <p>{contents}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
