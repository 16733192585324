import React, { Component } from "react";
import "../asset/Style.css";
// import thumb1 from "../asset/img/thumb1.png";
// import thumb2 from "../asset/img/thumb2.png";
import newthumb1 from "../asset/img/like-button-icon.png";
import newthumb2 from "../asset/img/dislike-button-icon.png";
import newthumbLike from "../asset/img/like-active-button.png";
import newthumbDisLike from "../asset/img/dislike-active--button.png";
// import forward from "../asset/img/forward.png";
// import downward from "../asset/img/downward.png";
import forward from "../asset/img/plusIcon.png";
import downward from "../asset/img/minusIcon.png";
import Help from "../Component/Help";
// import dislikeThumb from "../asset/img/dislike.png";
// import likeThumb from "../asset/img/like.png";
import loader from "../asset/img/loader.gif";
import Project from "../Configure/Project";
import Header from "../Component/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import _ from "lodash";
import Cookies from "js-cookie";
import Feedback from "../Component/Feedback";
import { pushCustomGAEvent, pushToDataLayer } from "../shared/FaGTM";
import FaSession from "../helper/FaSession";
import FetchApi from "./FetchApi";
import { isFloweraura } from "../helper";

let dislikeImproveData = [];
let dislikeDefaultData = [];
let commentId = 0;
let productComment = [];
let order_id = 0;
let commentArr = [];

class Product extends Component {
  constructor(props) {
    super(props);
    _.isEmpty(this.props.match)
      ? (order_id = this.props.order_id_Thanks)
      : (order_id = this.props.match.params.id);
    this.goBack = this.goBack.bind(this);
    this.state = {
      toggle: false,
      toggle2: false,
      selectedOption: "",
      isLoaded: false,
      isOpened: false,
      products: [],
      prodPost: [],
      btns: false,
      tempBtns: false,
      headingHide: true,
      feedbackHide: false,
      dislike: 2,
      progress: false,
      selectedButton: null,
      selectedBtnId: false,
      particalarButtonId: "",
      selectedProductId: null,
      product_type: null,
      thumbshowDislike: newthumb2,
      thumbshowLike: newthumb1,
      product_category: null,
      like: 1,
      submitButtonRes: false,
      isActiveDislike: "circle first",
      isActiveLike: "circle",
      selectedButtonStatus: 1,
      ContinueButtonRes: false,
      name: Cookies.get(`${order_id}`),
      headerMsg: "How was the quality of the gift received by you?",
      order_feedback_status: null,
      disLikeClick: false,
      count: 0,
      indexValue: "",
    };
    this.productRef = React.createRef();
    this.commentRef = React.createRef();
  }

  componentDidMount() {
    let { match, order_id_Thanks } = this.props;
    let url = "order/get-order-status/";

    if (
      typeof match === "undefined" ||
      match.params === "" ||
      match === "undefined"
    ) {
      url += order_id_Thanks;
    } else {
      url += match.params.id;
    }
    this.props.feedback_submit !== true &&
      FetchApi.getData(url)
        .then((res) => {
          if (typeof res.data !== "undefined") {
            let resData = res.data,
              resObj = "";
            if (typeof resData.data !== "undefined") {
              resObj = resData.data[0];
            } else {
              resObj = resData[0];
            }
            Cookies.set(`${order_id}`, resObj.receiver_name);
            Cookies.set("userMobile", resObj.receiver_mobile);
            if (resObj.order_feedback && resObj.product_feedback === false) {
              this.setState({
                order_feedback_status: resObj.order_feedback_status,
              });
              const eventData1 = {
                pageType: "product_feedback"
              }
              pushCustomGAEvent(eventData1)
              pushToDataLayer("clickEvent", eventData1);
              //pushToDataLayer("PageType", { type: "product_feedback" });
              const eventData2 = {
                'action': "Step 2",
                'eventCategory': 'Updated_Feedback',
                'eventLabel': "Open"
              }
              pushCustomGAEvent(eventData2)
              pushToDataLayer("clickEvent", eventData2);
              //pushToDataLayer("ProductPageLoad");
            }
          }
        })
        .catch((err) => {
          // Handle the error here.
        });
  }

  getProductRef = (element) => {
    this.productRef = element;
  };

  getCommentRef = (element) => {
    this.commentRef = element;
  };

  toaster = (msg) =>
    toast(msg, { containerId: "B", position: "Top", duration: "500" });

  tip = () => {
    const eventData = {
      'action': "Tips",
      'eventCategory': 'Updated_Feedback',
      'eventLabel': "Step2"
    }
    pushCustomGAEvent(eventData)
    pushToDataLayer("clickEvent", eventData);
    //pushToDataLayer("TipsClick", { page: "Step2" });
    this.setState({ toggle: !this.state.toggle });
  };

  scrollToMyRef = (type) => {
    if (type === "product" && this.productRef !== null) {
      return window.scrollTo({
        top: this.productRef.offsetTop,
        behavior: "smooth",
      });
    } else if (type === "comment" && this.commentRef !== null) {
      return window.scrollTo({
        top: this.commentRef.offsetTop,
        behavior: "smooth",
      });
    }

    return 0;
  };

  dislikeImg = () => {
    const eventData = {
      'action': "Step 2",
      'eventCategory': 'Updated_Feedback',
      'eventLabel': "Dislike"
    }
    pushCustomGAEvent(eventData)
    pushToDataLayer("clickEvent", eventData);
    //pushToDataLayer("ProductStatusClick", { status: "Dislike" });
    this.setState({
      thumbshowDislike: newthumbDisLike,
      thumbshowLike: newthumb1,
      isActiveDislike: "circle first active",
      isActiveLike: "circle",
      disLikeClick: true
    });
    this.toggleBox();
  };

  likeImg = () => {
    const eventData = {
      'action': "Step 2",
      'eventCategory': 'Updated_Feedback',
      'eventLabel': "Like"
    }
    pushCustomGAEvent(eventData)
    pushToDataLayer("clickEvent", eventData);
    //pushToDataLayer("ProductStatusClick", { status: "Like" });
    this.setState({
      thumbshowDislike: newthumb2,
      thumbshowLike: newthumbLike,
      isActiveDislike: "circle first",
      isActiveLike: "circle active",
    });
  };

  toggleImage = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  heading = (status) => {
    if (status === "like") {
      this.setState({ headingHide: true });
    } else {
      this.setState({ headingHide: false });
    }
  };

  progressHide = (status) => {
    if (status === "like") {
      this.setState({ progress: false });
    } else {
      this.setState({ progress: true });
    }
  };

  feedbackDiv = (status) => {
    this.setState({ feedbackHide: !this.state.feedbackHide });
  };

  btnOnClick = (e, prod) => {
    this.setState({ ContinueButtonRes: true });
    const elementId = e.target.getAttribute("id");
    if (elementId === "like") {
      this.setState({ selecteBtndId: 0, selectedButtonStatus: 1 });
    } else {
      this.setState({ selectedButtonStatus: 2 });
    }

    this.heading(elementId);
    this.feedbackDiv(elementId);
    this.progressHide(elementId);

    fetch(
      `${Project.apiBaseUrl}order/product-list/${order_id}?merch_id=${
        isFloweraura() ? 1 : 2
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({ ContinueButtonRes: false });
        if (result.data.length > 0) {
          dislikeDefaultData = [];
          dislikeImproveData = [];
          for (let i = 0; i < result.data.length; i++) {
            let buttonData = {
              order_id: order_id,
              product_id: `${result.data[i].product_id}`,
              comment_id: `${result.data[i].comment_id}`,
              user_id: "0",
              feedback_by: "2",
              rating: "0",
              status: this.state.selectedButtonStatus,
            };
            dislikeDefaultData.push(buttonData);
          }
          let arrWithCount = [...result.data]
              let countArr = arrWithCount.map((item)=> ({
                ...item,
                count: 0

              }))
          this.setState({
            activeButton: elementId,
            backgroundColor: "green",
            selectedOption: elementId,
            isLoaded: true,
            products: countArr,
          });

          setTimeout(() => {
            this.scrollToMyRef("product");
          }, 100);
        } else if (result.status === 404) {
          this.setState({
            activeButton: elementId,
            backgroundColor: "green",
            selectedOption: elementId,
            isLoaded: true,
            products: result.data,
          });
          this.toaster("Feedback already submitted");
        } else if (result.status === 201) {
          this.props.history.push("/Thankyou/" + order_id);
        }
      })
      .catch((error) => {
        this.setState({ ContinueButtonRes: false });
        this.toaster("Data not found !");
        this.setState({
          isLoaded: true,
          error,
        });
      });
  };

  closePanel = () => {
    if (this.state.selectedProductId !== "") {
      this.setState((prevState) => ({ selectedProductId: null }));
    }
  };

  toggleBox = (product_id, category, type, elem) => {
    if (
      product_id !== undefined &&
      this.state.selectedProductId !== product_id
    ) {
      this.setState((oldState) => ({ selectedProductId: product_id }));

      if (!productComment.hasOwnProperty(product_id)) {
        axios({
          method: "post",
          url: Project.apiBaseUrl + "product/comments-list/",
          data: {
            product_type: type,
            product_category: category,
            status: this.state.selectedButtonStatus,
          },
        })
          .then((response) => {
            if (response.data) {
              commentArr[product_id] = response.data.data;
              this.setState({
                isLoaded: true,
                btns: commentArr,
                tempBtns: commentArr,
              });

              setTimeout(() => {
                this.scrollToMyRef("product");
              }, 100);
            }
          })
          .catch((error) => {
            this.setState({
              isLoaded: true,
              error,
            });
            this.toaster("Data not found!");
          });
      }
    }
  };

  feedback = () => {
    this.setState({ toggle2: !this.state.toggle2 });
  };

  goBack() {
    this.props.history.goBack();
  }

  buttonClick = (btn) => {
    let product_id = this.state.selectedProductId;
    let temArr = [...this.state.products], temArrbtns = [...this.state.btns[product_id]];
    let prodIndex = temArr.findIndex((item) => item.product_id === product_id);
    let btnIndex = temArrbtns.findIndex((item) => item.id === btn.id);

    if (temArrbtns[btnIndex].is_active) {
        temArr[prodIndex].count = temArr[prodIndex].count - 1;
        this.setState({
            products: temArr
        })
    } else {
        temArr[prodIndex].count = temArr[prodIndex].count + 1;
        this.setState({
            products: temArr
        })
    }
    if (
      productComment[product_id] !== undefined &&
      productComment[product_id].length > 0
    ) {
      productComment[product_id] = productComment[product_id];
    } else {
      productComment[product_id] = [];
    }
    this.state.btns.map((data, key) => {
      if (key === product_id) {
        data.map((value, index) => {
          if (value.id === btn.id) {
            value.is_active = !value.is_active;
          }
        });
      }
    });
    this.setState({ btns: this.state.btns });
    commentId = commentId + btn.id + ",";
    let buttonData = {
      order_id: order_id,
      product_id: this.state.selectedProductId,
      comment_id: commentId === false ? "0" : commentId,
      user_id: "0",
      feedback_by: "2",
      rating: "0",
      status: this.state.selectedButtonStatus,
    };
    let commentFound = productComment[product_id].indexOf(btn.id);
    if (commentFound !== -1) {
      productComment[product_id].splice(commentFound, 1);
    } else {
      productComment[product_id].push(btn.id);
    }
    const found = dislikeImproveData.some((el) => el.product_id === product_id);
    if (!found) {
      dislikeImproveData.push(buttonData);
    }

    if (dislikeImproveData.length > 0) {
      let obj = dislikeImproveData.findIndex(
        (el) => el.product_id === product_id
      );
      if (productComment[product_id].toString() !== "") {
        dislikeImproveData[obj].comment_id = productComment[
          product_id
        ].toString();
      } else {
        dislikeImproveData[obj] = [];
      }
    }
  };

  isButtonActive(buttonId) {
    return this.state.activeButton === buttonId;
  }
  handleSubmit = () => {
    let finalData = [];
    let res = [];
    let route = "";
    dislikeImproveData.filter(function (el) {
      if (Object.keys(el).length > 0) {
        res.push(el);
      }
    });
    if (res.length <= 0) {
      finalData = dislikeDefaultData;
    } else {
      finalData = res;
    }

    let { products, selectedButtonStatus } = this.state;
    if (finalData.length > 0) {
      let submitGTMData =
        selectedButtonStatus === 2 &&
        finalData.map((data, index) => {
          const productDetails = products.filter(
            (product) =>
              parseInt(data.product_id) === parseInt(product.product_id)
          );
          const selectedComments = data.comment_id.split(",");
          if (
            selectedComments.length > 0 &&
            productDetails.length > 0 &&
            typeof commentArr[data.product_id] !== "undefined"
          ) {
            if (selectedComments.length > 0) {
              const getComment = selectedComments.map((commentId) => {
                const machedComment = commentArr[data.product_id].filter(
                  (row) => row.id === parseInt(commentId)
                );
                return machedComment.length > 0 && machedComment[0].name;
              });
              let productTitle = productDetails[0].title;
              return productTitle + ", " + getComment.join(",");
            }
          }
          return [];
        });

      let gtmRaw = "Submit | ";
      if (selectedButtonStatus === 2) {
        gtmRaw += "Dislike";
        if (submitGTMData.length > 0 && submitGTMData[0].length > 0) {
          gtmRaw += " | " + submitGTMData.join(" | ");
        }
      } else {
        gtmRaw += "Like";
      }

      const eventData = {
        'action': "Step 2",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': gtmRaw
      }
      pushCustomGAEvent(eventData)
      pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer("ProductSubmit", { submitData: gtmRaw });
      this.setState({ submitButtonRes: true });
      axios({
        method: "post",
        url: Project.apiBaseUrl + "product/save-feedback/",
        data: {
          prodPost: finalData,
          merch_id: isFloweraura() ? 1 : 2, //2 for Bk & 1 for FA,
        },
      })
        .then((response) => {
          if (response.data.status === 404) {
            this.toaster("Data not found!");
          } else {
            // if (
            //   this.state.order_feedback_status === 1 &&
            //   this.state.selectedButtonStatus === 1
            // ) {
            //   this.props.history.push("/Email/" + order_id);
            // } else {
            //   this.props.history.push("/Feedback_Detail/" + order_id);
            // }
            this.props.history.push("/Feedback_Detail/" + order_id);
            FaSession.set("isProductFeedbackSubmitted", true);
            FaSession.set("isFeedbackSubmitted", true);
          }

          this.setState({ submitButtonRes: false });
        })

        .catch((error) => {
          this.toaster("Data not found!");
        });
    } else {
      this.toaster("Please select feedback");
    }
  };

  render() {
    return (
      <div className="container container-revamp">
        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          position={toast.POSITION.TOP}
          duration={5000}
        />
        <Header
          help={() => this.tip()}
          name={Cookies.get(`${order_id}`)}
          content={this.state.headerMsg}
        />
        <div className="detail-contentInner">
        <div className="detail-box">
          <em></em>
          <h2>Hi {Cookies.get(`${order_id}`)},</h2>
          {/* <div className="progress-bar" /> */}
          {!this.state.disLikeClick ? 
          <p className="font">
            Are you happy with the delight you received?
          </p>:
           <p className="font font-product">
           What can we improve? </p>
          }

          {!this.state.disLikeClick && <div className={isFloweraura() ? "review-box review-like-revamp fa" : "review-box review-like-revamp bk"}>
            <div
              //className="circle"
              onClick={this.btnOnClick}
              id="dislike"
              className={this.state.isActiveDislike}
            >
              {this.state.ContinueButtonRes && (
                <div>
                  <div className="overlay-loader" />
                  <img className="loader" src={loader} alt="loader" />
                </div>
              )}
              <img
                className="thumb"
                src={this.state.thumbshowDislike}
                id="dislike"
                alt="thumb2"
                onClick={this.dislikeImg}
              />
               <span>Unsatisfied</span>
              {this.state.ContinueButtonRes && (
                <div>
                  <div className="overlay-loader" />
                  <img className="loader" src={loader} alt="loader" />
                </div>
              )}
            </div>
            <div
              className={this.state.isActiveLike}
              onClick={this.btnOnClick}
              id="like"
            >
              <img
                className="thumb"
                src={this.state.thumbshowLike}
                id="like"
                alt="thumb1"
                onClick={this.likeImg}
              />
               <span>Satisfied</span>
            </div>
          </div>}
        </div>
        {/* {this.state.progress ? <div className="progress-bar" /> : null} */}
        {this.state.disLikeClick &&
        <div className="gift-container">
          {this.state.selectedButtonStatus === 2
            ? this.state.products.map((prod) => (
                <div
                  className={prod.count > 0 ? "giftwrapper border-enable" : "giftwrapper"}
                  key={prod.product_id}
                  onClick={() =>
                    this.toggleBox(
                      prod.product_id,
                      prod.product_category,
                      prod.product_type
                    )
                  }
                  ref={this.getProductRef}
                >
                  <div className="giftbox" onClick={this.closePanel}>
                    <img
                      className="addproduct"
                      src={prod.image}
                      alt="product"
                    />
                    <div>
                      <p className="textsize">{prod.title}</p>

                      <p className="delivered">{prod.delivery_date}</p>
                    </div>
                  </div>

                  <img
                    className={
                      this.state.selectedProductId === prod.product_id
                        ? "add downward"
                        : "add"
                    }
                    src={
                      this.state.selectedProductId === prod.product_id
                        ? downward
                        : forward
                    }
                    alt="forward"
                    onClick={() => {
                      this.toggleBox();
                      this.closePanel();
                    }}
                  />
                  {this.state.selectedProductId === prod.product_id && <div className="hrline2" />}
                  {prod.count > 0 && <span className="disLikeCount">{prod.count}</span>}
                  {this.state.selectedProductId === prod.product_id ? (
                    <div className="gift-view flow" ref={this.getCommentRef}>
                      {/* {this.state.btns[this.state.selectedProductId] !==
                        undefined &&
                        this.state.btns[this.state.selectedProductId].length >
                          0 && <p className="font-btn">What can we improve?</p>} */}
                      {this.state.btns &&
                        this.state.btns.map((btn, index) =>
                          btn.map((data, inner) =>
                            this.state.selectedProductId === index ? (
                              <button
                                key={inner}
                                className={
                                  data.is_active ? "giftbtngreen" : "giftbtn"
                                }
                                onClick={() => this.buttonClick(data)}
                              >
                                {data.name}
                                {data.is_active && <em></em>}
                              </button>
                            ) : null
                          )
                        )}
                    </div>
                  ) : null}
                  {/* <div className="hrline1" /> */}
                </div>
              ))
            : null}
        </div>}

        {/*
        {this.state.feedbackHide ? (
          <div className="feedback">
            <div className="link-col" onClick={this.feedback}>
              <u> Leave more feedback</u>
            </div>
          </div>
        ) : null}
        */}
        {this.state.toggle ? (
          <div
            className="overlay-box"
            onClick={() => this.setState({ toggle: false })}
          />
        ) : null}
        {this.state.toggle ? <Help /> : null}
        {this.state.toggle2 ? <div className="overlay-box" /> : null}
        {this.state.toggle2 ? <Feedback click={() => this.feedback()} /> : null}
        {this.state.submitButtonRes && (
          <div>
            <div className="overlay-loader" />
            <img className="loader" src={loader} alt="loader" />
          </div>
        )}
        <div className={isFloweraura() ? "btn-background btn-revamp fa" : "btn-background btn-revamp bk"}>
          {/* <div className="progress-bar1" /> */}
          <button
            disabled={this.state.selectedOption.length > 0 ? false : true}
            className={
              this.state.selectedOption.length > 0
                ? "submit-btn1 btnactive"
                : "submit-btn1"
            }
            onClick={() => this.handleSubmit()}
            id="submit1"
          >
            Submit
          </button>
        </div>
        </div>
      </div>
    );
  }
}
export default Product;
