import React, { Component } from "react";
import "../../asset/Style.css";
import logo from "../../asset/img/logo.png";
import bulb from "../../asset/img/bulb.png";
import backgrd from "../../asset/img/backgrd.png";
import bkbackgrd from "../../asset/img/bk-backgrd.png";
import { isFloweraura } from "../../helper";
let customer_name = "";
let contents = "";
let hiddenclassName = ""
//let msgStatus = "";
const bklogo =
  "https://media.bakingo.com/sites/default/files/ssr-static/bakingo.svg";
const falogo = "https://imgcdn.floweraura.com/fa-logo/final-23-desk.svg";
class Header extends Component {
  render() {
    customer_name = this.props.customer_name;
    contents = this.props.content;
    hiddenclassName = this.props.hiddenclassName;

    return (
      <div>
        <img className="Group-img" src={isFloweraura() ? backgrd : bkbackgrd} alt="backgrd" />
        <div className="header">
          <img
            className="logo"
            src={isFloweraura() ? falogo : bklogo}
            alt="logo"
          />
          <div className={`heading ${hiddenclassName}`}>
            <p className="username"> Hey {customer_name},</p>
            <p>{contents}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
