import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";
import "./index.css";
import { createBrowserHistory } from "history";
import App from "./Component/App";
import Product from "./Component/Product";
import Feedback from "./Component/Feedback";
import Thankyou from "./Component/Thankyou";
import Email from "./Component/Email";
import FeedbackDetail from "./Component/FeedbackDetail";
import * as serviceWorker from "./serviceWorker";
import { initializeGTM } from './shared/FaGTM';
import { LoadJs } from './helper';
import CustomerFeedBack from "./Component/customerFeedBack";
import FeedbackCustomer from "./Component/customerFeedBack/Feedback";
import ThankyouPage from "./Component/customerFeedBack/ThankyouPage";
import DislikeParameter from "./Component/customerFeedBack/DislikeParameter";

const callback = () => {
  if (process.env.REACT_APP_JQUERY_PATH) {
    let PATH = process.env.REACT_APP_JQUERY_PATH;

    // This is just to for local to test if jquery loaded.
    // if (window.location.hostname === 'localhost') {
      PATH = 'https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js';
    // }

    LoadJs(PATH, true).then((response) => {
      initializeGTM();
    }).catch((error) => {
      console.log('jquery not loaded');
    })
  }
}


const history = createBrowserHistory();
ReactDOM.render(
  <Router history={history}>
    <Route path="/home" exact component={App} />
    <Route path="/home/:id" exact component={App} />
    <Route path="/Product/:id" component={Product} />
    <Route path="/Feedback/:id" component={Feedback} />
    <Route path="/Thankyou/:id" component={Thankyou} />
    <Route path="/Email/:id" component={Email} />
    <Route path="/Feedback_Detail/:id" component={FeedbackDetail} />
    <Route path="/customer-service-feedback/:id"  component={CustomerFeedBack} />
    <Route path="/negative" exact component={DislikeParameter} />
    <Route path="/feedback" exact component={FeedbackCustomer} />
    <Route path="/thankyou" exact component={ThankyouPage} />
  </Router>,
  document.getElementById("root"), callback
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
