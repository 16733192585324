import React, { Component } from "react";
import "../asset/Style.css";
// import thumb1 from "../asset/img/thumb1.png";
// import thumb2 from "../asset/img/thumb2.png";
import newthumb1 from "../asset/img/like-button-icon.png";
import newthumb2 from "../asset/img/dislike-button-icon.png";
import newthumbLike from "../asset/img/like-active-button.png";
import newthumbDisLike from "../asset/img/dislike-active--button.png";
// import dislikeThumb from "../asset/img/dislike.png";
// import likeThumb from "../asset/img/like.png";
import Help from "../Component/Help";
import Header from "../Component/Header";
import loader from "../asset/img/loader.gif";
import Project from "../Configure/Project";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import { pushCustomGAEvent, pushToDataLayer } from "../shared/FaGTM";
import FaSession from "../helper/FaSession";
import { isFloweraura } from "../helper";
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

let orderComment = [];
let order_id = 0;

class App extends Component {
  constructor(props) {
    super(props);
    order_id = this.props.match.params.id;
    this.state = {
      toggle: false,
      toggle1: false,
      selectedOption: "",
      error: null,
      items: [],
      selectedButtonStatus: null,
      selectedButton: [],
      selectedBtndId: false,
      open: true,
      order: 2,
      buttonState: "Btn2",
      thumbshowDislike: newthumb2,
      thumbshowLike: newthumb1,
      ContinueButtonRes: false,
      isActiveDislike: "circle first",
      isActiveLike: "circle",
      userName: "",
      headerMsg: "How was your gifting experience with our Delivery Partner?",
      userMobile: "",
      openBottomSheet: false,
    };

    this.commentRef = React.createRef();
  }

  getCommentRef = (element) => {
    this.commentRef = element;
  };

  scrollToMyRef = (type) => {
    if (type === "comment" && this.commentRef !== null) {
      return window.scrollTo({
        top: this.commentRef.offsetTop,
        behavior: "smooth",
      });
    }

    return 0;
  };

  componentDidMount() {
    localStorage.setItem("orderIdFeedbackStatus", false);
    axios
      .get(Project.apiBaseUrl + "order/get-order-status/" + order_id)
      .then((res) => {
        if (typeof res.data !== "undefined") {
          let resObj = res.data.data[0];
          if (typeof resObj.receiver_name !== "undefined") {
            this.setState({ userName: resObj.receiver_name });
            Cookies.set(`${order_id}`, resObj.receiver_name);
            Cookies.set("userMobile", resObj.receiver_mobile);
          }
          if (resObj.order_feedback && resObj.product_feedback) {
            if (resObj.is_order_feedback_submit === true) {
              localStorage.setItem("orderIdFeedbackStatus", true);
              this.props.history.push("/Thankyou/" + order_id, {
                feedback_submit: true,
              });
            } else if (
              resObj.order_feedback_status === 1 &&
              resObj.product_feedback_status === 1
            ) {
              this.props.history.push("/Email/" + order_id);
            } else {
              this.props.history.push("/Feedback_Detail/" + order_id);
            }
          } else if (resObj.order_feedback) {
            this.props.history.push("/Product/" + order_id);
          } else {
            const eventData1 = {
              pageType: "delivery_feedback"
            }
            pushCustomGAEvent(eventData1)
            pushToDataLayer("clickEvent", eventData1);
            //pushToDataLayer("PageType", { type: "delivery_feedback" });
            const eventData2 = {
              'action': "Step 1",
              'eventCategory': 'Updated_Feedback',
              'eventLabel': "Open"
            }
            pushCustomGAEvent(eventData2)
            pushToDataLayer("clickEvent", eventData2);
            //pushToDataLayer("DeliveryPageLoad");
          }
        }
      })
      .catch((err) => {
        // Handle the error here.
        console.log(err);
      });
  }

  dislikeImg = () => {
    this.setState({
      thumbshowDislike: newthumbDisLike,
      thumbshowLike: newthumb1,
      isActiveDislike: "circle first active",
      isActiveLike: "circle",
      openBottomSheet: true,
    });
  };

  likeImg = () => {
    this.setState({
      thumbshowDislike: newthumb2,
      thumbshowLike: newthumbLike,
      isActiveDislike: "circle first",
      isActiveLike: "circle  active",
    });
  };

  toaster = (msg) =>
    toast(msg, { containerId: "A", position: "Top", duration: "500" });

  buttonClick = (item, index) => {
    if (typeof orderComment == "string") {
      orderComment = orderComment.split(" ");
    }
    if (orderComment === 0) {
      orderComment = [];
    }

    let commentFound = orderComment.indexOf(item.id);
    if (commentFound !== -1) {
      orderComment.splice(commentFound, 1);
    } else {
      orderComment.push(item.id);
    }
    this.setState((prevState) => {
      return {
        items: prevState.items.map((data) => {
          if (data.id === item.id) {
            return { ...data, is_active: !item.is_active };
          } else {
            return data;
          }
        }),
        selectedButton: item.name,
        selectedBtndId: this.state.selectedBtndId + item.id + ",",
      };
    });
  };
  toggleImage = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  _handleOnClick = (e) => {
    this.setState({ ContinueButtonRes: true });
    const elementId = e.target.getAttribute("id");

    if (elementId === "like") {
      var comment_type = 1;
      this.setState({ selecteBtndId: 0, selectedButtonStatus: 1 });
      const eventData = {
        'action': "Step 1",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': 'Like'
      }
      pushCustomGAEvent(eventData)
      pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer("DeliveryStatusClick", { status: "Like" });
    } else {
      const eventData = {
        'action': "Step 1",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': 'Dislike'
      }
    pushCustomGAEvent(eventData)
    pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer("DeliveryStatusClick", { status: "Dislike" });
      this.setState({ selectedButtonStatus: 2 });
      comment_type = 2;
    }

    fetch(Project.apiBaseUrl + "order/comments-list/" + comment_type)
      .then((res) => res.json())
      .then((result) => {
        this.setState({ ContinueButtonRes: false });
        if (result.status === 200 && result.data.length > 0) {
          this.setState((state) => ({ open: !state._handleOnClickopen }));
          this.toggleImage();
          this.setState({
            activeButton: elementId,
            backgroundColor: "green",
            selectedOption: elementId,
            isLoaded: true,
            items: result.data,
          });

          setTimeout(() => {
            this.scrollToMyRef("comment");
          }, 100);
        } else {
          this.setState({
            selectedOption: elementId,
          });
          if (elementId === "like") {
            this.likeImg();
          } else {
            this.dislikeImg();
          }
        }
      })

      .catch((error) => {
        this.setState({ ContinueButtonRes: false });
        this.toaster("Data not found !");
        this.setState({
          isLoaded: true,
          error,
        });
      });
  };

  tip = () => {
    const eventData = {
      'action': "Tips",
      'eventCategory': 'Updated_Feedback',
      'eventLabel': "Step1"
    }
    pushCustomGAEvent(eventData)
    pushToDataLayer("clickEvent", eventData);
    //pushToDataLayer("TipsClick", { page: "Step1" });
    this.setState({ toggle: !this.state.toggle });
  };

  _isButtonActive(buttonId) {
    return this.state.activeButton === buttonId;
  }

  _handleSubmit = () => {
    if (orderComment.length <= 0 || this.state.selectedButtonStatus === 1) {
      this.setState.selectedBtndId = "0";
    } else {
      this.setState.selectedBtndId = orderComment.toString();
    }
    if (this.state.selectedButtonStatus) {
      FaSession.set("isOrderFeedbackSubmitted", true);

      let submitData = "Submit | ";
      if (this.setState.selectedBtndId !== "0") {
        submitData += "Dislike";
      } else {
        submitData += "Like";
      }

      //let thumbType = 'Like';
      if (this.setState.selectedBtndId !== "0") {
        let selectedComments = this.setState.selectedBtndId.split(",");
        let commentList = this.state.items;

        const getComment = selectedComments.map((commentId) => {
          const machedComment = commentList.filter(
            (row) => row.id === parseInt(commentId)
          );
          return machedComment[0].name;
        });

        if (getComment.length > 0) {
          submitData += " | " + getComment.join(" , ");
        }
      }
      const eventData = {
        'action': "Step 1",
        'eventCategory': 'Updated_Feedback',
        'eventLabel': submitData
      }
      pushCustomGAEvent(eventData)
      pushToDataLayer("clickEvent", eventData);
      //pushToDataLayer("DeliverySubmit", { submitData });

      this.setState({ ContinueButtonRes: true });
      axios({
        method: "post",
        url: Project.apiBaseUrl + "order/save-feedback",
        data: {
          order_id: order_id,
          comment_id: this.setState.selectedBtndId,
          user_id: "0",
          feedback_by: "2",
          rating: "0",
          merch_id: isFloweraura() ? 1 : 2,
          status: this.state.selectedButtonStatus,
        },
      })
        .then((response) => {
          this.setState({ ContinueButtonRes: false });
          if (response.data.status === 404) {
            this.toaster(response.data.msg);
          } else if (response.data.status === 400) {
            this.toaster("Invalid Order");
          } else if (response.data.status === 201) {
            this.props.history.push("/Feedback_Detail/" + order_id);
          } else if (response.data.status === 500) {
            this.toaster("Something went wrong. Please try after some time");
          } else {
            this.props.history.push("/Product/" + order_id);
          }
        })
        .catch((error) => {
          this.toaster(error);
        });
    } else {
      this.toaster("Please select feedback");
    }
  };

  slideButtomHandler = () => {
    this.setState ({
        openBottomSheet : true
    })
}

onDismiss = () => {
    this.setState ({
      openBottomSheet : false,
      thumbshowDislike: newthumb2,
      selectedOption: ""
    })
}

  render() {
    return (
      <div className="container container-revamp">
        <ToastContainer
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP}
          duration={5000}
        />
        <Header
          help={() => this.tip()}
          // name={this.state.userName}
          // content={this.state.headerMsg}
        />

        {/* <div className="progress-bar" /> */}
        <div className="detail-contentInner">
        <div className="detail-box">
          <em></em>
          <h2>Hi {this.state.userName},</h2>
          <p className="font">
            {this.state.headerMsg}
          </p>
          <div className={isFloweraura() ? "review-box review-like-revamp fa" : "review-box review-like-revamp bk"}>
            <div
              //className="circle"
              onClick={this._handleOnClick}
              id="dislike"
              className={this.state.isActiveDislike}
            >
              {this.state.ContinueButtonRes && (
                <div>
                  <div className="overlay-loader" />
                  <img className="loader" src={loader} alt="loader" />
                </div>
              )}
              <img
                className="thumb"
                src={this.state.thumbshowDislike}
                id="dislike"
                alt="thumb2"
                onClick={this.dislikeImg}
              />
              <span>Unsatisfied</span>
            </div>
            <div
              className={this.state.isActiveLike}
              onClick={this._handleOnClick}
              id="like"
            >
              <img
                className="thumb"
                src={this.state.thumbshowLike}
                id="like"
                alt="thumb1"
                onClick={this.likeImg}
              />
              <span>Satisfied</span>
            </div>
          </div>
          {this.state.selectedButtonStatus === 2 &&
          this.state.items.length > 0 ? (
            // <div className="flow" ref={this.getCommentRef}>
            //   <div className="line" />
            //   {this.state.items.length > 0 && (
            //     <p className="font-btn">What can we improve?</p>
            //   )}
            //   {this.state.items &&
            //     this.state.items.map((item, index) => (
            //       <button
            //         key={index}
            //         className={item.is_active ? "Btn1" : "Btn2"}
            //         onClick={() => this.buttonClick(item)}
            //       >
            //         {item.name}
            //       </button>
            //     ))}
            // </div>
              <BottomSheet
                open={this.state.openBottomSheet}
                onDismiss={this.onDismiss}
              >
             <div className="flow dislikeContent">
              {this.state.items.length > 0 && (
                <p className="font-btn">What can we improve?</p>
              )}
              <div className="dislikeButton">
              {this.state.items &&
                this.state.items.map((item, index) => (
                  <button
                    key={index}
                    className={item.is_active ? "Btn1" : "Btn2"}
                    onClick={() => this.buttonClick(item)}
                  >
                    {item.name}
                    {item.is_active && <em></em>}
                  </button>
                ))}
                </div>
                <div className={isFloweraura() ? "btn-background btn-revamp fa" : "btn-background btn-revamp bk"}>
                  <button
                    className="submit-btn1 btnactive"
                    onClick={this._handleSubmit.bind(this)}
                    id="submit"
                  >
                    Continue
                  </button>
                </div>
            </div>
          </BottomSheet>
          ) : null}
        </div>
        {this.state.toggle ? (
          <div
            className="overlay-box"
            onClick={() => this.setState({ toggle: false })}
          />
        ) : null}
        {this.state.toggle ? <Help /> : null}
        {this.state.ContinueButtonRes && (
          <div>
            <div className="overlay-loader" />
            <img className="loader" src={loader} alt="loader" />
          </div>
        )}
        <div className={isFloweraura() ? "btn-background btn-revamp fa" : "btn-background btn-revamp bk"}>
          {/* <div className="progress-bar1" /> */}
          <button
            disabled={this.state.selectedOption.length > 0 ? false : true}
            className={
              this.state.selectedOption.length > 0
                ? "submit-btn1 btnactive"
                : "submit-btn1"
            }
            onClick={this._handleSubmit.bind(this)}
            id="submit"
          >
            Continue
          </button>
        </div>
        </div>
      </div>
    );
  }
}

export default App;
