const BASE_URL = window.location.origin;
const HOST_NAME = window.location.hostname;
const isFloweraura = !HOST_NAME.includes(".bakingo.com") && !HOST_NAME.includes(".userfeedbackbk.bakewish.in");
const isBakingo = HOST_NAME.includes(".bakingo.com") || HOST_NAME.includes(".userfeedbackbk.bakewish.in");
const bakingo_endpoint = "https://bkapis.bakingo.com/api/";
const bk_staging_endpoint = "https://userfeedbackbk.bakewish.in/api/";
const fa_endpoint = "https://faapis.floweraura.com/api/";
const fa_staging_endpoint = "https://userfeedback.bakewish.in/api/";
const agent_endpoint_prod = "https://opr.floweraura.com/api/";
const agent_endpoint_staging = 'https://devoprnew.bakewish.in/api/';
const isProduction = 1;
const endpoint = isBakingo ? bakingo_endpoint : fa_endpoint;
const agent_endpoint = isProduction ? agent_endpoint_prod : agent_endpoint_staging;

export default {
  apiBaseUrl: endpoint,
  agentBaseUrl: agent_endpoint,
  perPageProducts: 20,
  HOST_NAME: window.location.hostname,
  BASE_URL: BASE_URL
};
