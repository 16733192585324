import Config from "../Configure/Project.js";
import FaSession from "./FaSession";

export const LoadJs = (url, async, defer, nonce) => {
  nonce = nonce || "4219487942";
  async = async || false;
  defer = defer || false;
  return new Promise(function (resolve, reject) {
    var parent = "body";
    var script = document.createElement("script");
    script.setAttribute("src", url);
    script.setAttribute("type", "text/javascript");
    async && script.setAttribute("async", true);
    defer && script.setAttribute("defer", true);
    script.setAttribute("crossorigin", "anonymous");
    nonce && script.setAttribute("nonce", nonce);
    script.onload = function (e) {
      resolve(script);
    };
    script.onerror = function (err) {
      console.error("Error Loading Js", err);
      reject(err);
    };
    document[parent].appendChild(script);
  });
};

export const isProduction = () => {
  const HOST_NAME = Config.HOST_NAME;
  const DOMAIN = process.env.REACT_APP_DOMAIN;
  const DOMAIN_LIST = DOMAIN.split("|");
  if (DOMAIN_LIST.includes(HOST_NAME)) {
    return true;
  }
  return false;
};
export const isFloweraura = () => {
  const HOST_NAME = window.location.hostname;
  if (HOST_NAME.includes(".bakingo.com") || HOST_NAME.includes("userfeedbackbk.bakewish.in")) return false;
  else return true;
};
export const isFeedbackSubmitted = (type) => {
  let alreadySubmitted = FaSession.get(type);
  if (alreadySubmitted) {
    return Boolean(alreadySubmitted);
  }
  return false;
};