import React, { Component } from "react";
import "../asset/Style.css";
class Help extends Component {
  render() {
    return (
      <div className="container">
        <div className="description">
          <p>
            Help others while you review us!
            <br />
            You can include the following points while you write to us!
            <br />
            1. Delivery Condition - How was it?
            <br />
            2. Product Quality - Whether value for money or not?
            <br />
            3. Mention what you liked or disliked while shopping with us
            <br />
            4. Were our customer service executive helpful enough
            <br />
            5. How was your overall shopping experience?
            <br />
            6. Pass on the word if we made you happy
          </p>
        </div>
      </div>
    );
  }
}
export default Help;
