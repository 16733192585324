export default {
  'DeliveryPageLoad': {
    event: "clickEvent",
    eventAction: "Step 1",
    eventCategory: "Feedback",
    eventLabel: "Open",
    uniqueEventId: 51,
  },
  'TipsClick': {
    event: "clickEvent",
    eventAction: "Tips",
    eventCategory: "Feedback",
    eventLabel: "<%-page%>",
    uniqueEventId: 51,
  },
  'DeliveryStatusClick': {
    event: "clickEvent",
    eventAction: "Step 1",
    eventCategory: "Feedback",
    eventLabel: "<%-status%>",
    uniqueEventId: 51,
  },
  'DeliverySubmit': {
    event: "clickEvent",
    eventAction: "Step 1",
    eventCategory: "Feedback",
    eventLabel: "<%-submitData%>",
    uniqueEventId: 51,
  },
  'ProductPageLoad': {
    event: "clickEvent",
    eventAction: "Step 2",
    eventCategory: "Feedback",
    eventLabel: "Open",
    uniqueEventId: 51,
  },
  'ProductStatusClick': {
    event: "clickEvent",
    eventAction: "Step 2",
    eventCategory: "Feedback",
    eventLabel: "<%-status%>",
    uniqueEventId: 51,
  },
  'ProductSubmit': {
    event: "clickEvent",
    eventAction: "Step 2",
    eventCategory: "Feedback",
    eventLabel: "<%-submitData%>",
    uniqueEventId: 51,
  },
  'ThankyouPageLoad': {
    event: "clickEvent",
    eventAction: "Step 3",
    eventCategory: "Feedback",
    eventLabel: "Thank You",
    uniqueEventId: 51,
  },
  'AlreadySubmittedPageLoad': {
    event: "clickEvent",
    eventAction: "Step 1",
    eventCategory: "Feedback",
    eventLabel: "Already submitted",
    uniqueEventId: 51,
  },
  'PageType': {
    pageType: "<%-type%>"
  }
}
