import React, { Component } from "react";
import "../../asset/Style.css";
import './index.css';
import axios from "axios";
import Project from "../../Configure/Project";
import loader from "../../asset/img/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import Header from "./Header";
import ThankyouPage from "./ThankyouPage";
import { isFloweraura } from "../../helper";
class FeedbackCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: "",
            ContinueButtonRes: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    toaster = (msg) =>
        toast(msg, { containerId: "A", position: "Top", duration: "500" });

    handleChange = event => {
        this.setState({ comments: event.target.value });
    };

    Save = () => {
        if (this.state.comments !== "") {
            this.setState({
                ContinueButtonRes: true,
            })
            const headers = {
                // 'Origin': 'bakewish',
                'Auth': localStorage.getItem("token") || ''
            }
            axios({
                method: "post",
                headers: headers,
                url: Project.agentBaseUrl + "Customer_feedback_api/saveCustomerFeedbackComment",
                data: {
                    "insert_id": localStorage.getItem("insert_id"),
                    "comment": this.state.comments
                }
            })
                .then(response => {
                    console.log(response.data.alreadyGiven);
                    if (response && response.status === 200) {
                        this.setState({
                            ContinueButtonRes: false,
                        })
                        if(response.data.alreadyGiven === 1) 
                        return this.toaster("Comment already has been given for this maaping id!");
                        else {
                            this.props.history.push("/Thankyou")
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        } else {
            this.toaster("Please message required");
        }
    };
    Skip = () => {
        this.props.history.push("/Thankyou")
    }
    render() {
        return (
            <div className="container">
                <ToastContainer
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP}
                    duration={5000}
                />
                {this.state.ContinueButtonRes && (
                    <div>
                        <div className="overlay-loader" />
                        <img className="loader" src={loader} alt="loader" />
                    </div>
                )}
                <div className="feedback-show">
                    <Header />
                    <h2 className="More-heading">Is there anything more you wish to share with me?</h2>
                    <textarea
                        className="feedback-para"
                        placeholder="Write your feedback here"
                        value={this.state.comments}
                        onChange={this.handleChange}
                    />
                    
                    <div className={isFloweraura() ? "skip-submit fa": "skip-submit bk"}>
                    <div className="progress-bar1"></div>
                        <div className="two-button_set">
                            <button className="skip-btn" onClick={this.Skip}>
                                SKIP
                    </button>
                            <button className="done-btn" onClick={this.Save}>
                                SUBMIT
                    </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default FeedbackCustomer;
